//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger';
import LangSelect from '@/components/LangSelect';
import db from '@/utils/localstorage';
import Screenfull from '@/components/Screenfull';
import Search from '@/components/HeaderSearch';
import socket from '@/utils/socket';
export default {
  components: {
    // Breadcrumb,
    Hamburger: Hamburger,
    LangSelect: LangSelect,
    Screenfull: Screenfull,
    Search: Search
  },
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.setting.sidebar;
    },
    avatar: function avatar() {
      return require("@/assets/avatar/".concat(this.$store.state.account.user.avatar));
    },
    username: function username() {
      return this.$store.state.account.user.nickName || this.$store.state.account.user.username;
    },
    device: function device() {
      return this.$store.state.setting.device;
    }
  },
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.commit('setting/toggleSidebar');
    },
    setting: function setting() {
      this.$store.commit('setting/openSettingBar', true);
    },
    logout: function logout() {
      this.clean();
    },
    clean: function clean() {
      socket.close();
      db.clear();
      location.reload();
    },
    deleteCache: function deleteCache() {
      this.$confirm(this.$t('tips.confirmDeleteCache'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(function () {
        db.remove('USER_ROUTER');
        db.remove('PERMISSIONS');
        location.reload();
      }).catch(function () {
        // do nothing
      });
    }
  }
};