//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    language: function language() {
      return this.$store.state.setting.language;
    }
  },
  methods: {
    handleSetLanguage: function handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.commit('setting/setLanguage', lang);
      this.$message({
        message: this.$t('tips.switchLanguageSuccess'),
        type: 'success'
      });
    }
  }
};