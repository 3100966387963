//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    logo: function logo() {
      return require('@/assets/logo.png');
    },
    logoSmall: function logoSmall() {
      return require('@/assets/logo-small.png');
    }
  }
};