//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Hamburger',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    isActive: {
      default: false
    }
  },
  methods: {
    toggleClick: function toggleClick() {
      this.$emit('toggleClick');
    }
  }
};