"use strict";

var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _toConsumableArray2 = _interopRequireDefault(require("/Users/zhubo/work/xzb/taxpay-web-admin/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AppMain',
  data: function data() {
    return {
      exclude: ['Yue', 'Invalid']
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    },
    cache: function cache() {
      // console.log(this.$route.meta.noCache)
      return this.$route.meta.noCache;
    },
    excludes: function excludes() {
      return [this.exclude].concat((0, _toConsumableArray2.default)(this.$store.state.setting.exclude));
    }
  },
  watch: {
    '$route': {
      handler: function handler(to, from) {
        var _this = this;
        // 监听路由，详情页滚动置顶
        var path = to.path;
        if (path.includes('detail')) {
          this.$nextTick(function () {
            _this.$refs.appMain.scrollTo(0, 0);
          });
        }
      },
      deep: true
    }
  }
};
exports.default = _default;