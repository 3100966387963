"use strict";

var _interopRequireDefault = require("/Users/zhubo/work/xzb/taxpay-web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/zhubo/work/xzb/taxpay-web-admin/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _index = _interopRequireDefault(require("@/store/index"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
_vue.default.use(_vueRouter.default);
var constRouter = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/redirect/index'], resolve);
    }
  }]
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/error-page/404'], resolve);
  },
  hidden: true
}, {
  path: '/login',
  name: '登录页',
  component: function component(resolve) {
    return require(['@/views/login/index'], resolve);
  }
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/dashboard/index'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/profile',
  component: _layout.default,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/profile/index'], resolve);
    },
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
}, {
  path: '/error',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'ErrorPages',
  meta: {
    title: 'errorPages',
    icon: '404'
  },
  children: [{
    path: '404',
    component: function component(resolve) {
      return require(['@/views/error-page/404'], resolve);
    },
    name: 'Page404',
    meta: {
      title: 'page404',
      noCache: true
    }
  }]
}];
var router = new _vueRouter.default({
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constRouter,
  mode: 'history'

  // base: process.env.VUE_APP_BASE_URL,
});

var whiteList = ['/login'];
var asyncRouter;

// 导航守卫，渲染动态路由
router.beforeEach(function (to, from, next) {
  _nprogress.default.start();
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    var token = _localstorage.default.get('ACCESS_TOKEN');
    var user = _localstorage.default.get('USER');
    console.log(asyncRouter);
    var userRouter = get('USER_ROUTER');
    if (token.length && user) {
      _index.default.commit('account/setTokenStatus', true);
      if (!asyncRouter) {
        if (!userRouter) {
          _request.default.get("system/menu/".concat(user.username)).then(function (res) {
            var permissions = res.data.data.permissions;
            _index.default.commit('account/setPermissions', permissions);
            asyncRouter = res.data.data.routes;
            // asyncRouter = [{
            //   alwaysShow: true,
            //   hidden: false,
            //   meta: {title: "支付通道管理", icon: "el-icon-data-line", breadcrumb: true},
            //   name: "支付通道管理",
            //   path: "/payChannel",
            //   component: Layout,
            //   children: [
            //     {
            //       path: '/payChannel',
            //       component: (resolve) => require(['@/views/payChannel/index'], resolve),
            //       name: 'PayChannel',
            //       meta: { title: '支付通道管理', icon: 'user', noCache: true }
            //     }
            //   ]
            // }]
            _index.default.commit('account/setRoutes', asyncRouter);
            save('USER_ROUTER', asyncRouter);
            go(to, next);
          }).catch(function () {
            localStorage.clear();
            window.location.reload();
          });
        } else {
          asyncRouter = userRouter;
          console.log(userRouter);
          go(to, next);
        }
      } else {
        next();
      }
    } else {
      if (to.path === '/login') {
        next();
      } else {
        next('/login');
      }
    }
  }
});
router.afterEach(function () {
  _nprogress.default.done();
});
function go(to, next) {
  asyncRouter = filterAsyncRouter(asyncRouter);
  router.addRoutes(asyncRouter);
  // console.log(asyncRouter)
  // console.log(router)
  next((0, _objectSpread2.default)((0, _objectSpread2.default)({}, to), {}, {
    replace: true
  }));
}
function save(name, data) {
  localStorage.setItem(name, JSON.stringify(data));
}
function get(name) {
  return JSON.parse(localStorage.getItem(name));
}
function filterAsyncRouter(routes) {
  return routes.filter(function (route) {
    var component = route.component;
    if (component) {
      if (route.component === 'Layout') {
        route.component = _layout.default;
      } else {
        route.component = view(component);
      }
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children);
      }
      return true;
    }
  });
}
function view(path) {
  return function (resolve) {
    return require(["@/views/".concat(path, ".vue")], resolve);
  };
}
var _default = router;
exports.default = _default;