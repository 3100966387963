import { Loading } from 'element-ui';
var loadingCount = 0;
var loading;
var startLoading = function startLoading(dom) {
  if (dom) {
    loading = Loading.service({
      text: 'Loading...',
      target: document.querySelector(dom)
    });
  } else {
    loading = Loading.service({
      text: '加载中...',
      spinner: 'el-icon-loading',
      lock: true,
      background: 'rgba(0, 0, 0, 0.3)'
    });
  }
};
var endLoading = function endLoading() {
  loading.close();
};
export var showLoading = function showLoading(dom) {
  if (loadingCount === 0) {
    startLoading(dom);
  }
  loadingCount++;
};
export var hideLoading = function hideLoading() {
  if (loadingCount <= 0) {
    return;
  }
  loadingCount--;
  if (loadingCount === 0) {
    endLoading();
  }
};