import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/Users/zhubo/work/xzb/taxpay-web-admin/node_modules/core-js/modules/es6.array.iterator.js";
import "/Users/zhubo/work/xzb/taxpay-web-admin/node_modules/core-js/modules/es6.promise.js";
import "/Users/zhubo/work/xzb/taxpay-web-admin/node_modules/core-js/modules/es6.object.assign.js";
import "/Users/zhubo/work/xzb/taxpay-web-admin/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets
import Element from 'element-ui';
import 'font-awesome/css/font-awesome.min.css';
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import _i18n from "./lang"; // internationalization
import "./icons"; // icon
import "./utils/error-log"; // error log
import request from '@/utils/request';
import * as filters from "./filters"; // global filters
import 'default-passive-events';
import { hasPermission, hasNoPermission, hasAnyPermission } from "./utils/permissionDirect";
var Plugins = [hasPermission, hasNoPermission, hasAnyPermission];

// Element.Select.computed.readonly = function () {
//   console.log(!Number.isNaN(Number(document.documentMode)))
//   console.log(document)
//   const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode))
//   return !(this.filterable || this.multiple || !isIE) && !this.visible
// }

Plugins.map(function (plugin) {
  Vue.use(plugin);
});
Vue.use(Element, {
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.prototype.$post = request.post;
Vue.prototype.$get = request.get;
Vue.prototype.$put = request.put;
Vue.prototype.$delete = request.delete;
Vue.prototype.$download = request.download;
Vue.prototype.$upload = request.upload;
Vue.prototype.$login = request.login;
Vue.prototype.$tips = '系统提示';

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
if (process.env.NODE_ENV == 'production') {
  if (Window) {
    window.console.log = function () {};
  }
}
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});