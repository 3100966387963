import db from '@/utils/localstorage';
var state = {
  merId: db.get('MER_ID', null),
  pageType: db.get('MER_PAGE_TYPE', ''),
  pageDisable: db.get('MER_PAGE_DISABLE', false),
  levyBtnType: db.get('MER_LEVY_BTNTYPE', true),
  merBtnLoading: db.get('MER_BTNLOADING', false)
};
var mutations = {
  setMerId: function setMerId(state, val) {
    db.save('MER_ID', val);
    state.merId = val;
  },
  setPageType: function setPageType(state, val) {
    db.save('MER_PAGE_TYPE', val);
    state.pageType = val;
  },
  setPageDisable: function setPageDisable(state, val) {
    db.save('MER_PAGE_DISABLE', val);
    state.pageDisable = val;
  },
  setLevyBtnType: function setLevyBtnType(state, val) {
    db.save('MER_LEVY_BTNTYPE', val);
    state.levyBtnType = val;
  },
  setBtnLoading: function setBtnLoading(state, val) {
    db.save('MER_BTNLOADING', val);
    state.merBtnLoading = val;
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};