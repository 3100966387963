import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _objectSpread from "/Users/zhubo/work/xzb/taxpay-web-admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import db from '@/utils/localstorage';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang
import enLocale from "./en";
import zhLocale from "./zh";
Vue.use(VueI18n);
var messages = {
  en: _objectSpread(_objectSpread({}, enLocale), elementEnLocale),
  zh: _objectSpread(_objectSpread({}, zhLocale), elementZhLocale)
};
export function getLanguage() {
  var chooseLanguage = db.get('LANGUAGE', '');
  if (chooseLanguage) return chooseLanguage;

  // if has not choose language
  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);
  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'zh';
}
var i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages: messages,
  silentTranslationWarn: true
});
export default i18n;