import { Notification } from 'element-ui';
import store from '@/store/index';
var socket = {
  websocket: null,
  connection: function connection() {
    var id = store.state.account.user.userId;
    this.websocket = new WebSocket("".concat(process.env.VUE_APP_BASE_WS, "/message/ist/").concat(id));
    console.log(this.websocket);
  },
  onopen: function onopen() {
    this.websocket.onopen = function (event) {
      // console.log('链接socket')
    };
  },
  getMessage: function getMessage() {
    this.websocket.onmessage = function (event) {
      var message = JSON.parse(event.data);
      if (message.textMessage) {
        Notification({
          title: '系统提示',
          message: message.textMessage,
          duration: 5000
        });
      }
    };
  },
  onclose: function onclose() {
    this.websocket.onclose = function (event) {
      // connection()
      console.log('断开socket');
    };
  },
  close: function close() {
    this.websocket.close();
  }
};
export default socket;