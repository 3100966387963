import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from '@/styles/variables.scss';
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  computed: {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.setting.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.$store.state.setting.sidebar.opened;
    },
    userRoutes: function userRoutes() {
      return this.$store.state.account.routes;
    }
  },
  methods: {
    // 点击刷新页面
    menuResetPage: function menuResetPage(key, keyPath) {
      // return
      var url = this.$route.fullPath;
      if (key === url) {
        this.$router.replace({
          path: '/redirect' + url
        });
      }
      this.$route.meta.noCache = true;
    }
  }
};