import "core-js/modules/es7.array.includes";
//
//
//
//
//
//
//

import socket from '@/utils/socket';
import db from '@/utils/localstorage';
export default {
  name: 'App',
  data: function data() {
    return {
      // 用户超时定时器
      timmer: null
    };
  },
  methods: {
    moveEvent: function moveEvent() {
      var path = ['/login'];
      if (!path.includes(this.$route.path)) {
        clearTimeout(this.timmer);
        this.init();
      }
    },
    init: function init() {
      this.timmer = setTimeout(function () {
        socket.close();
        // this.$router.push({
        //   path: "/login",
        // })
        db.clear();
        location.reload();
      }, 1200000);
    }
  }
};